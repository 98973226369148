@import '~antd/dist/antd.compact.css';
.ant-table.ant-table-small .ant-table-tbody > tr > td {
	padding: 2px 4px;
	font-size: 0.95em;
}
.ant-table-column-title,
.ant-table.ant-table-small .ant-table-thead > tr > th {
	font-size: 0.9em;
}
.table__middle .ant-table.ant-table-small .ant-table-tbody > tr > td {
	padding: 4px 6px;
	font-size: 0.9em;
}
.table__middle .ant-table-column-title,
.table__middle .ant-table.ant-table-small .ant-table-thead > tr > th {
	font-size: 1em;
}
.table__large .ant-table.ant-table-small .ant-table-tbody > tr > td {
	padding: 8px 8px;
	font-size: 1.1em;
}
.table__large .ant-table-column-title,
.table__large .ant-table.ant-table-small .ant-table-thead > tr > th {
	font-size: 1.2em;
}
.ant-form-item {
	margin-bottom: 8px;
}
.ant-affix {
	padding: 4px 0;
	background-color: white;
}
